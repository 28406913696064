<!-- 政策库-->
<template>
  <div style="background-color: #fff">
    <right-nav @back="backSearch" @forward="forwardSearch"></right-nav>
    <div class="def-container" style="box-sizing: border-box">
      <!-- <div class="mainTitle">
        <div
          v-for="(item, index) in matterList"
          :key="index"
          :style="{ backgroundImg: item.bgImg }"
          class="matterPart"
        >
          <div class="titlePart">
            <div class="imgPart">
              <img
                :src="item.iconImg"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="wt_matter_title">{{ item.name }}</div>
          </div>
          <div :class="'titleNum' + index">
            <span>{{ item.num }}</span>
          </div>
        </div>
      </div> -->
      <div style="margin: 20px auto">
        <el-radio-group v-model="radioCheck" @change="radioCheckChange">
          <el-radio-button label="全部" value="全部"></el-radio-button>
          <el-radio-button label="国家" value="国家"></el-radio-button>
          <el-radio-button label="省级" value="省级"></el-radio-button>
          <el-radio-button label="市级" value="市级"></el-radio-button>
          <el-radio-button label="区/县级" value="区/县级"></el-radio-button>
        </el-radio-group>
      </div>
      <div style="margin-bottom: 20px" v-show="radioCheck == '区/县级'">
        <el-radio-group v-model="radioCheck2" @change="radioCheckChange2">
          <el-radio-button
            v-for="(item, index) in getDictList('policy_level_qx')"
            :key="index"
            :label="item.label"
            :value="item.key"
          ></el-radio-button>
        </el-radio-group>
      </div>
      <FilterGroup @change="changeSearch">
        <!-- <FilterCheckBox
          v-model="searchInfo.data.dq"
          label="政策级别"
          :list="getDictList('policy_level')"
          valueKey="key"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBox>
        <FilterCheckBox
          v-model="searchInfo.data.qysx"
          label="企业属性"
          :list="getDictList('enterprise_attributes')"
          valueKey="key"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBox> -->
        <FilterCheckBoxMine
          ref="refHylb"
          v-model="searchInfo.data.hyle"
          label="行业类别"
          :list="getDictList('sys_category')"
          valueKey="key"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBoxMine>
        <FilterCheckBoxMine
          ref="refQysx"
          v-model="searchInfo.data.qysx"
          label="企业属性"
          :list="getDictList('enterprise_attributes')"
          valueKey="key"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBoxMine>
        <FilterCheckBoxMine
          ref="refQygm"
          v-model="searchInfo.data.qygm"
          label="企业规模"
          :list="getDictList('enterprise_size').slice(1)"
          valueKey="key"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBoxMine>
        <!-- <FilterCheckBox
          v-model="searchInfo.data.deptcode"
          label="发布部门"
          :list="searchInfo.deptList"
          valueKey="deptid"
          labelKey="deptname"
          fold
        ></FilterCheckBox> -->
        <!-- 行业主管部门多选 -->
        <!-- <FilterCheckBox
          v-model="searchInfo.data.hyzgbm"
          label="行业主管部门"
          :list="searchInfo.hyzgbmList"
          valueKey="deptid"
          labelKey="deptname"
          fold
        ></FilterCheckBox> -->
        <!-- <FilterItem
          v-model="searchInfo.data.hyzgbm"
          label="主管部门"
          :list="searchInfo.hyzgbmList"
          valueKey="deptid"
          labelKey="deptname"
          fold
        ></FilterItem> -->
        <!-- <FilterCheckBoxMine
          v-model="searchInfo.data.fbjg"
          label="发布机构"
          :list="searchInfo.fbjgList"
          valueKey="deptid"
          labelKey="deptname"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBoxMine> -->
        <FilterCheckBoxMine
          ref="refFbjg"
          v-model="searchInfo.data.fbjg"
          label="发布机构"
          :list="searchInfo.fbjgList"
          valueKey="key"
          :needAll="true"
          :type="'1'"
          fold
        ></FilterCheckBoxMine>
        <!-- <FilterCheckBox
          v-model="searchInfo.data.zcfs"
          label="支持方式"
          :list="getDictList('sq_policy_zjlx')"
          :needAll="true"
          :type="'1'"
          valueKey="key"
          fold
        ></FilterCheckBox> -->
        <FilterItem label="已选择条件:" :needAll="false">
          <div class="filter-params-inner">
            <div class="filterItem-params-left">
              <span
                style="
                  color: #409eff;
                  font-size: 16px;
                  margin-left: 40px;
                  margin-left: 20px;
                "
                v-for="(item, index) in cpu_paramsList"
                :key="index"
              >
                {{ item.label }}
              </span>
              <span
                v-if="!cpu_paramsList.length"
                style="color: #fff; margin-left: 20px"
                >无</span
              >
              <el-button
                v-else
                style="
                  color: #fff;
                  background-color: #22b7fd;
                  padding: 4px 12px;
                "
                @click="cleanParams"
                >清空</el-button
              >
            </div>
          </div>
        </FilterItem>
      </FilterGroup>
      <div class="banner-limit banner-info">
        <!-- <div class="sort">
          <span class="txt">分类</span>
          <el-select v-model="menuIndex">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="input-search-bar">
          <el-select
              class="custom-select"
              v-model="option"
              placeholder="请选择搜索类型"
              clearable
              type="primary"
              @change="changeOption"
              style="width: 20%;"
          >
            <el-option
                v-for="(item, index) in queryOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>

          <div v-show="showFlag == 1">
            <el-input  class="input-search" style="width: 470px;" v-model="keyWords" placeholder=""></el-input>
          </div>
          <el-cascader v-show="showFlag == 2"
                       :options="fbjgDeptList"
                       filterable
                       clearable
                       :multiple="true"
                       v-model="fbjgDeptName"
                       style="width: 100%;height: 100%"
                       :show-all-levels="false"
                       :props="optionProps"
                       @change="changeDept"
                       class="input-search"
          >
          </el-cascader>
<!--          <el-input class="input-search" v-model="keyWords" placeholder="">-->
<!--          </el-input>-->
          <el-button class="input-search-btn" type="primary" @click="toSearch" style="height: 45px"
            >搜索
          </el-button>
        </div>
      </div>
      <div class="resultTop flex-row">
        <span style="font-size: 18px"
          >相关结果约 <span>{{ total }}</span> 条</span
        >
      </div>
      <div class="resultList flex-row-between">
        <div class="resultList-left">
          <el-table
            :data="tableData"
            style="width: 100%"
            @row-click="toNotice"
            :show-header="false"
          >
            <el-table-column label="">
              <template slot-scope="scope">
                <div class="resultList-left-content">
                  <div style="margin-bottom: 10px">
                    <span class="title">最新政策</span>
                    <span class="headerTitle" v-html="scope.row.name"></span
                    ><span class="zcjd" v-if="scope.row.sfzcjd == '1'"
                      >政策解读</span
                    >
                  </div>
                  <div class="content">
                    <div>
                      <div class="txt" v-html="scope.row.content"></div>
                      <div class="subtilte">
                        <!-- <span class="subTitleItem"
                          ><span>扶持资金：</span
                          >{{ scope.row.supportWay || 0 }}</span
                        > -->
                        <span class="subTitleItem"
                          ><span>发布部门：</span>{{ scope.row.deptName }}</span
                        >
                        <span class="subTitleItem"
                          ><span>发布日期：</span
                          >{{ scope.row.publictime.split(" ")[0] }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            v-if="showPagination"
            style="margin-top: 10px"
            background
            :current-page="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next,total, jumper"
            prev-text="上一页"
            next-text="下一页"
            :total="total"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RightNav from "./components/rightNav.vue";
import {
  FilterGroup,
  FilterItem,
  // FilterCheckBox,
  FilterCheckBoxMine,
} from "./components/filter/index.js";
export default {
  components: {
    // FormInput,
    // DeclareCell,
    // SectionHeader,
    // TjIcon,
    // FilterRadio,
    FilterGroup,
    // FilterCheckBox,
    FilterCheckBoxMine,
    FilterItem,
    RightNav,
  },
  data() {
    return {
      searchObj: {
        deptid: "",
      },
      searchCacheArr: [],
      searchCacheIndex: 0,
      isClickToNotice: false,
      keyWords: "", //搜索关键字
      matterList: [
        {
          bgImg: require("@/assets/deyangImg/mainBg1.png"),
          iconImg: require("@/assets/deyangImg/mainTitle1.png"),
          name: "政策数量",
          num: 0,
        },
        {
          bgImg: require("@/assets/deyangImg/mainBg2.png"),
          iconImg: require("@/assets/deyangImg/mainTitle2.png"),
          name: "政策项目数",
          num: 0,
        },
        {
          bgImg: require("@/assets/deyangImg/mainBg3.png"),
          iconImg: require("@/assets/deyangImg/mainTitle3.png"),
          name: "企业办理项目数",
          num: 0,
        },
        {
          bgImg: require("@/assets/deyangImg/mainBg4.png"),
          iconImg: require("@/assets/deyangImg/mainTitle4.png"),
          name: "政策类型数",
          num: 0,
        },
      ],
      searchInfo: {
        data: {
          xmlb: "", // 项目类别
          cylb: "", // 产业类别
          deptcode: "", // 发布部门
          hyzgbm: "", //行业主管部门
          fbjg: "", //发布机构
          zcfs: "", // 支持方式
          temp_amount: "", // 资金限额
          zzxeleft: "", // 起始资金限额
          zzxeright: "", // 结束资金限额
          name: "", // 事项名称
          hyle: "", //行业类别
          zcfl: "", //政策分类
          dq: "", //地区
          qysx: "", //企业属性
          qygm: "", //企业规模
          growth: "", //成长周期
        },
        oldParams: {},
        deptList: [], // 职能部门列表
        hyzgbmList: [], //行业主管部门
        fbjgList: [], //发布机构
        amountList: [
          { value: "0", label: "1000万及以上" },
          { value: "1", label: "500万~1000万" },
          { value: "2", label: "100万~500万" },
          { value: "3", label: "100万及以下" },
          // { value: '4', label: '自定义' },
        ],
        areaList: [], //地区列表
        type: "0", // 搜索类型{'0':传统列表, '1':数字化列表}
      },
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      searchdata: "", //搜索输入数据
      searchDataHyle: "", //搜索行业类别需要的数据
      searchDataZcfl: "", //搜索政策分类需要的数据
      searchDataDq: "", //搜索地区需要的数据
      searchDataDept: "", //搜索部门需要的数据
      searchDataHyzgbm: "", //搜索行业主管部门需要的数据
      searchDataFbjg: "", //搜索发布机构需要的数据
      searchDataQysx: "", //搜索企业属性需要的数据
      searchDataQygm: "", //搜索企业规模需要的数据
      searchDataGrowth: "", //搜索成长周期需要的数据
      searchDataZcfs: "", //搜索支持方式需要的数据
      sysCategoryValue: [],
      enterpriseAttributesValue: [],
      enterpriseSizeValue: [],
      growthCycleValue: [],
      areaValue: [],
      policyClassificationValue: [],
      deptName: [],
      supportWayValue: [],
      hyzgbmValue: [],
      fbjgValue: [],
      radioCheck: "全部",
      radioCheck2: "",
      menuIndex: "",
      options: [],
      search: "",
      showPagination: true, //切换查询条件相应改变分页
      radioCheckCahce: [], //选择的条件
      qxareaidValue: [], //选择的区县
      option:[],//搜索选项
      queryOptions: [
        {
          label: "关键字",
          value: 1,
        },
        {
          label: "发布机构",
          value: 2,
        },
      ],
      fbjgDeptList:[],//下拉框选择发布机构时发布机构列表
      showFlag: 1,//显示关键字下拉框
      optionProps: {
        multiple: true,  //实现可以多选多个叶子节点
        // checkStrictly: true, //选择任意一级选项：在单选模式下，你只能选择叶子节点；而在多选模式下，勾选父节点真正选中的都是叶子节点。启用该功能后，可让父子节点取消关联，选择任意一级选项。
        expandTrigger: 'hover', //触碰选项可展示出子选项，无需点击
      },
      fbjgDeptName:""//发布机构
    };
  },
  created() {
    // let tempPageNum = sessionStorage.getItem('policyLibraryPagenum');
    // if(tempPageNum){
    //   this.pageNum = tempPageNum;
    //   sessionStorage.setItem('policyLibraryPagenum','')
    // }
    this.keyWords =
      sessionStorage.getItem("policyLibraryKeywords") || this.keyWords;
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb",
        "sys_enter_type",
        "sq_policy_zjlx",
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
        "sys_category", // 行业类别(国民经济分类)
        "policy_level", //政策层级
        "enterprise_attributes", //企业属性
        "enterprise_size", //企业规模
        "growth_cycle", //成长周期
        "policy_level_qx", //行政层级
      ],
    });
    // this.getTree();
    // this.getDeptList();
    this.getDeptData();
    this.getFbjgData();
  },
  beforeRouteEnter(to, from, next) {
    if (from.fullPath.indexOf("/notice/detail/") == -1) {
      sessionStorage.setItem("policyLibraryKeywords", "");
    }
    next();
  },
  mounted() {
    this.policyLibraryCountNum();
    this.getProjectLibrary(this.pageSize, this.pageNum);
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
      getDictLabel: "dictionary/getDictLabel",
    }),
    cpu_paramsList() {
      let list = [];
      // 政策级别
      if (this.searchDataDq || this.searchDataDq == "") {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataDq !== "") {
          arrData = this.searchDataDq.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("policy_level").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "dq",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 企业属性
      if (this.searchDataQysx) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataQysx !== "") {
          arrData = this.searchDataQysx.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("enterprise_attributes").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "qysx",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 行业类别
      if (this.searchDataHyle) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataHyle !== "") {
          arrData = this.searchDataHyle.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("sys_category").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "hyle",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 政策分类
      if (this.searchDataZcfl) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataZcfl !== "") {
          arrData = this.searchDataZcfl.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("sq_policy_xmlb").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "zcfl",
            value: listvalue.join(","),
            label: listlabel.join(","),
          });
        }
      }
      // 企业规模
      if (this.searchDataQygm) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataQygm !== "") {
          arrData = this.searchDataQygm.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("enterprise_size").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "qygm",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 成长周期
      if (this.searchDataGrowth) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataGrowth !== "") {
          arrData = this.searchDataGrowth.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("growth_cycle").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "growth",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 政策级别是单选的时候
      // if (this.searchDataDq) {
      //   list.push({
      //     type: "dq",
      //     value: this.searchDataDq,
      //     label: this.getDictList("policy_level").find(
      //       (cv) => cv.key === this.searchDataDq
      //     )?.label,
      //   });
      // }
      // if (this.searchDataDept) {
      //   list.push({
      //     type: "dept",
      //     value: this.searchDataDept,
      //     label: this.searchInfo.deptList.find(
      //       (cv) => cv.deptid === this.searchDataDept
      //     )?.deptname,
      //   });
      // }
      // 行业主管部门多选
      // if (this.searchDataHyzgbm) {
      //   let arrData = [];
      //   let listlabel = [];
      //   let listvalue = [];
      //   if (this.searchDataHyzgbm !== "") {
      //     arrData = this.searchDataHyzgbm.split(",");
      //     arrData.forEach((item) => {
      //
      //       listvalue.push(item);
      //       let label = this.searchInfo.hyzgbmList.find(
      //         (cv) => cv.deptid === item
      //       )?.deptname;
      //       listlabel.push(label);
      //     });
      //     list.push({
      //       type: "hyzgbm",
      //       value: listvalue.join("，"),
      //       label: listlabel.join("，"),
      //     });
      //   }
      // }
      // 行业主管部门单选
      if (this.searchDataHyzgbm) {
        list.push({
          type: "hyzgbm",
          value: this.searchDataHyzgbm,
          label: this.searchInfo.hyzgbmList.find(
            (cv) => cv.deptid === this.searchDataHyzgbm
          )?.deptname,
        });
      }
      // 发布机构
      if (this.searchDataFbjg) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataFbjg !== "") {
          arrData = this.searchDataFbjg.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.searchInfo.fbjgList.find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "fbjg",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      if (this.searchDataZcfs) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataZcfs !== "") {
          arrData = this.searchDataZcfs.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("sq_policy_zjlx").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "zcfs",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 去掉第一个逗号
      list.filter((item) => {
        if (item.label[0] == "，") {
          item.label = item.label.substring(1);
        }
      });
      this.cleansubmitParams();
      list.forEach((item) => {
        switch (item.type) {
          case "hyle":
            this.sysCategoryValue.push(item.label);
            break;
          case "qysx":
            this.enterpriseAttributesValue.push(item.label);
            break;
          case "qygm":
            this.enterpriseSizeValue.push(item.label);
            break;
          case "growth":
            this.growthCycleValue.push(item.label);
            break;
          case "dq":
            this.areaValue.push(item.label);
            break;
          case "zcfl":
            this.policyClassificationValue.push(item.label);
            break;
          case "zcfs":
            // this.deptName.push(item.label);
            break;
          case "hyzgbm":
            this.hyzgbmValue.push(item.label);
            break;
          case "fbjg":
            this.fbjgValue.push(item.label);
            break;
          default:
            break;
        }
      });
      this.getProjectLibrary(this.pageSize, this.pageNum);
      return list;
    },
  },
  methods: {
    //选中搜索中的发布机构时触发
    changeDept(data){
      let value = ""
      data.forEach((item) => {
        if(item.length == 1){
          value += item[0] + ","
        }else {
          value += item[1] + ","
        }
      })

      this.searchDataFbjg = value.slice(0, -1)// 移除最后一个逗号
    },
    changeOption(){
      this.searchDataFbjg = ""
      this.keyWords = "";
      this.fbjgDeptName = "";

      //选择发布机构时
      if(this.option == 2){

        this.showFlag = 2;
        let param = {}
        let url = "/dev-api/system/dept/treeCascaderPolicyDept"
        this.$httpApi.get(url, param).then((res) => {
          if (res.code == 200) {
            this.fbjgDeptList = res.data;
          }
        })
      }else {
        this.showFlag = 1;
      }
    },
    getDeptList() {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_dept_list";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          if (res.data && !!res.data.length) {
            this.searchInfo.deptList = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getDeptList error:", res);
        });
    },
    // 新的查询主管部门
    getDeptData() {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_dept_id_list"; //主管部门
      let params = {};
      this.$httpApi.post(url, params).then((res) => {
        if (res.data && !!res.data.length) {
          this.searchInfo.hyzgbmList = res.data || [];
        }
      });
    },
    // 新的发布机构查询
    getFbjgData() {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_fbjg_list"; //发布机构
      let params = {};
      this.$httpApi.post(url, params).then((res) => {
        if (res.data && !!res.data.length) {
          this.searchInfo.fbjgList = res.data || [];
        }
        let temp = this.searchInfo.fbjgList.map((item) => {
          return {
            key: item.deptid,
            label: item.deptname,
            short_name: item.short_name,
          };
        });
        this.searchInfo.fbjgList = temp;
      });
    },
    //返回上一次查询的结果
    backSearch() {
      if (this.searchCacheIndex > this.searchCacheArr.length - 1) {
        this.$router.go(-1);
      } else {
        this.searchCacheIndex++;
        if (this.searchCacheIndex == this.searchCacheArr.length) {
          this.radioCheck = "全部";
        } else {
          let search =
            this.searchCacheArr[
              this.searchCacheArr.length - 1 - this.searchCacheIndex
            ];
          this.keyWords = search.keyWords;
          this.radioCheck = search.radioCheck;
          this.radioCheck2 = search.radioCheck2;
        }
        // 处理参数
        if (!this.radioCheck) {
          this.qxareaidValue = [];
          this.getProjectLibrary(10, 1);
        } else {
          let arr = [];
          if (this.radioCheck == "全部") {
            this.qxareaidValue = [];
          } else {
            arr.push(this.radioCheck);
          }
          this.getProjectLibrary(10, 1, arr);
        }
      }
    },
    forwardSearch() {
      if (this.searchCacheIndex < 1) {
        this.$router.go(1);
      } else {
        this.searchCacheIndex--;
        let search =
          this.searchCacheArr[
            this.searchCacheArr.length - 1 - this.searchCacheIndex
          ];
        this.keyWords = search.keyWords;
        this.radioCheck = search.radioCheck;
        this.radioCheck2 = search.radioCheck2;
        // 处理参数
        if (!this.radioCheck) {
          this.qxareaidValue = [];
          this.getProjectLibrary(10, 1);
        } else {
          let arr = [];
          if (this.radioCheck == "全部") {
            this.qxareaidValue = [];
          } else {
            arr.push(this.radioCheck);
          }
          this.getProjectLibrary(10, 1, arr);
        }
      }
    },
    toSearch() {
      this.searchCacheArr.push({
        keyWords: this.keyWords,
        radioCheck: this.radioCheck,
        radioCheck2: this.radioCheck2,
      });
      this.getProjectLibrary(10, 1);
    },
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      //     资金限额自定义时
      switch (label) {
        case "行业类别":
          this.searchDataHyle = value;
          break;
        // case "政策分类":
        //   this.searchDataZcfl = value;
        //   break;
        case "政策级别":
          // value = value.substring(1);
          this.searchDataDq = value;
          break;
        // case "主管部门":
        //   this.searchDataDept = value;
        //   break;
        // case "发布部门":
        //   this.searchDataDept = value;
        //   break;
        case "主管部门":
          this.searchDataHyzgbm = value;
          break;
        case "发布机构":
          this.searchDataFbjg = value;
          break;
        case "企业属性":
          this.searchDataQysx = value;
          break;
        case "企业规模":
          this.searchDataQygm = value;
          break;
        case "成长周期":
          this.searchDataGrowth = value;
          break;
        case "支持方式":
          this.searchDataZcfs = value;
          break;
        default:
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );
      // this.pageNum = 1;
    },
    cleanParams() {
      // this.areaValue = [];
      // this.enterpriseAttributesValue = [];
      // this.enterpriseSizeValue = [];
      // this.growthCycleValue = [];
      // this.sysCategoryValue = [];
      // this.policyClassificationValue = [];
      // this.deptName = [];
      // this.supportWayValue = [];
      // this.pageNum = 1;
      // this.total = 0;
      this.searchDataHyle = "";
      this.searchDataZcfl = "";
      this.searchDataDq = "";
      this.searchDataDept = "";
      this.searchDataHyzgbm = "";
      this.searchDataFbjg = "";
      this.searchDataQysx = "";
      this.searchDataQygm = "";
      this.searchDataGrowth = "";
      this.searchDataZcfs = "";
      for (const k of Object.keys(this.searchInfo.data)) {
        this.searchInfo.data[k] = "";
      }
      this.$refs.refFbjg.selectItem({ label: "不限" });
      this.$refs.refHylb.selectItem({ label: "不限" });
      this.$refs.refQysx.selectItem({ label: "不限" });
      this.$refs.refQygm.selectItem({ label: "不限" });
      this.searchDataFbjg = ""
      this.fbjgDeptName = ""
    },
    cleansubmitParams() {
      this.areaValue = [];
      this.hyzgbmValue = [];
      this.fbjgValue = [];
      this.enterpriseAttributesValue = [];
      this.enterpriseSizeValue = [];
      this.growthCycleValue = [];
      this.sysCategoryValue = [];
      this.policyClassificationValue = [];
      this.deptName = [];
      this.supportWayValue = [];
      // this.pageNum = 1;
      this.total = 0;
    },
    policyLibraryCountNum() {
      let url = "/dev-api/business/sq-apply/policyLibraryCountNum";
      this.$httpApi
        .get(url)
        .then((r) => {
          this.matterList[0].num = r.policyNum;
          this.matterList[1].num = r.policyItemNum;
          this.matterList[2].num = r.qyItemNum;
          this.matterList[3].num = r.typeNum;
        })
        .catch((e) => {});
    },
    getProjectLibrary(pageSize, pageNum, radiocheck) {
      this.showPagination = false;
      let url = "/elastic/search/sq-policy";
      let params = {
        pageNum: pageNum,
        pageSize: pageSize,
        keyWords: this.keyWords,
        areaValue: this.areaValue,
        enterpriseAttributesValue: this.enterpriseAttributesValue,
        enterpriseSizeValue: this.enterpriseSizeValue,
        growthCycleValue: this.growthCycleValue,
        sysCategoryValue: this.sysCategoryValue,
        policyClassificationValue: this.policyClassificationValue,
        // deptName: this.deptName,
        deptName: this.hyzgbmValue,
        supportWayValue: this.supportWayValue,
        sortBy: this.keyWords ? "" : 3,
        // sortBy: 3,
        qxareaidValue: this.qxareaidValue,
        fbjgValue: this.fbjgValue,
      };
      if (radiocheck) {
        params.areaValue = radiocheck;
      }
      let that = this;
      this.tableData = [];
      that.$httpApi
        .post(url, params)
        .then((res) => {
          this.showPagination = true;
          let result = res.rows;
          result.forEach((item) => {
            item.areaValue = item.areaValue.join(",");
            item.enterpriseAttributesValue =
              item.enterpriseAttributesValue.join(",");
            item.enterpriseSizeValue = item.enterpriseSizeValue.join(",");
            item.sysCategoryValue = item.sysCategoryValue.join(",");
          });
          that.total = res.total;
          that.tableData = res.rows;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    currentChange(pageNum) {
      this.pageNum = pageNum;
      // sessionStorage.setItem('policyLibraryPagenum',this.pageNum);
      if (this.radioCheckCahce.length > 0) {
        this.getProjectLibrary(this.pageSize, pageNum, this.radioCheckCahce);
      } else {
        this.getProjectLibrary(this.pageSize, pageNum);
      }
    },
    radioCheckChange(val) {
      this.searchCacheArr.push({
        keyWords: this.keyWords,
        radioCheck: val,
        radioCheck2: this.radioCheck2,
      });
      let arr = [];
      if (!(val == "全部")) {
        arr.push(val);
      } else {
        this.qxareaidValue = [];
      }
      this.radioCheckCahce = arr;
      this.getProjectLibrary(10, 1, arr);
      // this.pageNum = 1;
    },
    radioCheckChange2(val) {
      this.searchCacheArr.push({
        keyWords: this.keyWords,
        radioCheck: this.radioCheck,
        radioCheck2: val,
      });
      let arr = [];
      arr.push(val);
      this.qxareaidValue = arr;
      this.getProjectLibrary(10, 1);
    },
    // 跳通知公告详情
    toNotice(row) {
      let that = this;
      that.isClickToNotice = true;
      sessionStorage.setItem("policyLibraryKeywords", this.keyWords);
      sessionStorage.setItem("policyCode", row.code);
      let { href } = that.$router.resolve({
        path: "/notice/detail/" + row.id,
        query:{
          // linkType:true,
        },
      });
      sessionStorage.setItem("linkType", true);
      // sessionStorage.setItem('policyLibrarayPageCache',JSON.stringify(this.searchCacheArr))
      window.open(href, "_blank");
      // window.open(href, "_self");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

::v-deep .el-cascader__search-input {
  z-index:-1;
  flex: 1;
  height: 35px;
  min-width: 60px;
  margin: -13px 0 2px 15px;
  padding: 0;
  color: #606266;
  border: none;
  outline: 0;
  box-sizing: border-box;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff;
  color: #1f59d8;
}
.mainTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-top: 20px;
  .matterPart {
    width: 24%;
    height: 120px;
    box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.1);
    .titlePart {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 10px;
      color: #333;
      .imgPart {
        width: 37px;
        height: 37px;
      }
      .wt_matter_title {
        font-size: 18px;
        font-weight: 900;
        margin-left: 5px;
      }
    }
    .titleNum0 {
      text-align: center;
      font-size: 30px;
      color: #ff5a5a;
    }
    .titleNum1 {
      text-align: center;
      font-size: 30px;
      color: #ff8a16;
    }
    .titleNum2 {
      text-align: center;
      font-size: 30px;
      color: #4bc83c;
    }
    .titleNum3 {
      text-align: center;
      font-size: 30px;
      color: #1064d8;
    }
  }
}
::v-deep {
  .el-table .el-table__header-wrapper th,
  .el-table .el-table__fixed-header-wrapper th {
    word-break: break-word;
    background-color: #65a4fc;
    color: #fff;
    font-size: 16px;
  }
  .el-pagination {
    text-align: right;
    margin: 20px;
  }
  .filterItem-right span {
    color: #409eff;
  }
  .filterItem-right .el-icon-caret-bottom {
    color: #409eff !important;
  }
  .filterItem-content .content-item.content-item--active {
    background-color: #409eff;
  }
  .filterItem-content .content-item:hover {
    color: #409eff;
  }
  .findList .filterItem-label,
  .findList .el-checkbox-button__inner {
    font-size: 18px !important;
  }
  .filterItem-label {
    font-size: 1rem;
    font-weight: 700;
  }
  .filterItem-content .content-item {
    font-size: 1.125rem;
  }
}
.resultList {
  width: 100%;
  min-height: 500px;
  .resultList-left {
    // width: 60%;
    min-width: 740px;
    width: 100%;
    min-height: 400px;
    .resultList-left-content {
      margin: 10px auto;
      cursor: pointer;
      &:hover {
        .headerTitle {
          color: #1f59d8;
        }
        .zcjd {
          border: 2px solid #1f59d8;
          color: #1f59d8;
        }
      }
    }
    .title {
      max-width: 95px;
      margin-right: 5px;
      background-color: #d30b15;
      border-left: 5px solid #d30b15;
      border-right: 5px solid #d30b15;
      font-size: 18px;
      color: #fff;
    }
    .headerTitle {
      font-size: 18px;
      color: #000;
      font-weight: 700;
      margin-right: 0px;
    }
    .zcjd {
      display: inline-block;
      padding: 2px 6px;

      border: 2px solid #03b1ff;
      border-radius: 5px;

      color: #03b1ff;
      font-weight: 600;
      margin-left: 10px;
      // &:hover {
      //   border: 2px solid #1f59d8;
      //   color: #1f59d8;
      // }
    }
    .content {
      // width: 740px;
      width: 100%;
      display: flex;
    }
    .tableDiv {
      // width: 740px;
      width: 100%;
      min-height: 136px;
      border: 1px solid #ccc;
      .row {
        min-height: 40px;
        display: flex;
        border-bottom: 1px solid #ccc;
        .borderRight {
          width: 50%;
          border-right: 1px solid #ccc;
        }
      }
      .row-last {
        // min-height: 40px;
        border: none;
        padding: 10px;
      }
      // .row p:nth-child(1) {
      //   width: 50%;
      //   border-right: 1px solid #ccc;
      // }
      .row p {
        padding: 10px;
      }
    }
    .img {
      width: 140px;
      height: 80px;
      margin-right: 20px;
      background-color: #e0e0e0;
    }
    .txt {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-height: 30px;
    }
    .subtilte {
      min-width: 1255px;
      margin-top: 20px;
      .subTitleItem {
        margin-right: 40px;
        span {
          font-weight: 600;
        }
      }
    }
  }
  .el-pager li {
    padding: 0 10px;
    background: #fff;
    border: 1px solid #ccc;
  }
  .el-pager li.active {
    background: #eee;
    color: #666;
  }
  .el-pagination.is-background {
    .btn-next,
    .btn-prev {
      padding: 0 15px;
      background: #fff;
      border: 1px solid #ccc;
    }
  }
  .resultList-right {
    // width: 38%;
    min-width: 250px;
    // background: lightgreen;
    margin-left: 150px;
    align-self: flex-start;
    height: 400px;
    .rank {
      min-height: 300px;
      border-left: 1px solid #ccc;
      padding-left: 20px;
      margin-top: 40px;
      p {
        font-size: 18px;
        padding-bottom: 10px;
      }
      i {
        display: inline-block;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        color: #fff;
        font-style: normal;
        background-color: #999;
        border-radius: 3px;
        margin-top: 4px;
        margin-right: 5px;
      }
      span {
        color: #333;
      }
      .topthree {
        background-color: #d30b15;
      }
    }
  }
}
.banner-info {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sort {
    width: 160px;
    height: 50px;
    position: relative;
    margin-left: 72px;
    ::v-deep .el-select .el-input__inner {
      font-size: 18px;
    }
    ::v-deep .el-select-dropdown__item {
      font-size: 18px !important;
    }
    .txt {
      font-size: 22px;
      font-weight: bold;
      position: absolute;
      top: 5px;
      left: -90px;
    }
  }
  .input-search-bar {
    margin-top: 10px;
    height: 55px;
    width: 50%;
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    /* 输入框头部 */
    .input-search-pre {
      width: 120px !important;
      color: #404040;
      font-size: 16px;
      background: $color-fff;
      .el-input__inner {
        text-align: center !important;
        border: none !important;
        border-radius: 0 !important;
        color: #333;
        background-color: #ffffff !important;
      }
    }
    /* 输入框内容 */
  .input-search {
      flex: 1;
      .el-input__inner {
        border: none !important;
        border-radius: 0 !important;
        background-color: #ffffff !important;
        font-size: 18px;
      }
      input::-webkit-input-placeholder {
        color: #999999;
      }
    }
    /* 输入框按钮 */
    .input-search-btn {
      background: #03b1ff;
      width: 100px;
      height: 45px;
      line-height: 14px;
      border-radius: 0 !important;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      // border: 2px solid #b6a99c !important;
      text-align: center;
      font-size: $font-size18;
      color: $color-fff;
      cursor: pointer;
      .el-button {
        font-weight: bold !important;
      }
    }
  }
  .hightLevelSearch {
    width: 120px;
    height: 55px;
    // background-color: coral;
    position: absolute;
    top: 30px;
    right: 138px;
    .input-search-btn {
      background: #03b1ff;
      height: 100%;
      width: 100%;
      border-radius: 4px;
      border: 2px solid #b6a99c !important;
      // text-align: center;
      font-size: $font-size18;
      color: $color-fff;
      cursor: pointer;
      .el-button {
        font-weight: bold !important;
      }
    }
  }
}

::v-deep .filter-item {
  padding: 0 !important;
}
::v-deep .filterItem-label {
  font-weight: normal !important;
}

::v-deep .el-select .el-input__inner {
  cursor: pointer;
  height: 45px;
  font-size: 16px;
  padding-right: 35px;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 45px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}


 ::v-deep .el-input__inner {
  border-radius: 0px !important;
  height: 45px !important;
}
</style>
